export interface ReviseModalParamsType {
  delete: {
    id: string;
    router: any;
    name: string;
    text: string;
    handlers: {
      yes: string;
      no: string;
    };
  };
  dateRange: {
    model: {
      dateFrom: ReviseModalDateType;
      dateTo: ReviseModalDateType;
    };
  };
}

export interface ReviseModalDateType {
  key: string;
  type: string;
  title: string;
  value: string;
  action: string;
  required: boolean;
  size: number;
  mask: {
    regex: string;
    placeholder: string;
    minChars: number;
  };
  error: {
    class: string;
    message: string;
  };
  validation: {
    empty: string;
  };
}

export const modalParams: ReviseModalParamsType = {
  delete: {
    id: '',
    router: null,
    name: '',
    text: 'Ты действительно хочешь удалить этот объект?',
    handlers: {
      yes: 'shops/deleteShopById',
      no: '',
    },
  },
  dateRange: {
    model: {
      dateFrom: {
        key: 'dateFrom',
        type: 'text',
        title: '',
        value: '',
        action: 'revise/updateDateFrom',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      dateTo: {
        key: 'dateTo',
        type: 'text',
        title: '',
        value: '',
        action: 'revise/updateDateTo',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
    },
  },
};
