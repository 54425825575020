import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import moment from 'moment/moment.js';
import { dateFormat, getFullName, getShortFullName, minutesToTime } from '@/lib/Utils';

export interface ReviseModelInterface {
  shiftId: number;
  employee: string;
  employeeId: string;
  vacancy: string;
  date: string;
  start: string;
  end: string;
  shiftLunch: number;
  shiftLunchIsPaid: boolean;
  time: number;
  lkTime: number;
  lkPrice: number;
  lkQuantity: number;
  lk: {
    start?: string;
    end?: string;
    lunch?: string;
    status: string;
    substatus: string;
  };
  gigant: {
    start?: string;
    end?: string;
    lunch?: string;
    status: string;
    substatus: string;
  };
  isTest: boolean;
  isTestMarket: boolean;
  unitType: { id: number; shortName: string; isHourly: boolean };
  quantity: number;
  price: number;
  comment: string;
}

export function prepareShopList(
  table: TableApiInterface,
  options?: { sort?: { sort: string; key: string }[] }
): ReturnType<UITable['getTable']> {
  const uiTable = new UITable(table);

  return uiTable
    .removeColumn('isTestClient')
    .removeColumn('isTestMarket')
    .removeColumn('countDone')

    .setProperty('code', 'name', 'Код')
    .setProperty('address', 'name', 'Адрес объекта')
    .setProperty('countUnreconciledShift', 'align', 'right')
    .setProperty('countDiscussionShift', 'align', 'right')
    .setProperty('checkupTime', 'align', 'right')
    .setProperty('checkupTime', 'name', 'Сверено')

    .setSortableValues(options?.sort)
    .getTable();
}

export function prepareReviseList(data: any, options: { sort?: any[]; month: string }) {
  const titles: any[] = [
    {
      id: 'fullName',
      name: 'ФИО',
      visible: true,
      sortable: true,
    },
  ];

  const month = options.month ? moment(options.month, 'YYYY-MM') : moment();
  const daysCount = month.daysInMonth();

  for (let i = 1; i <= daysCount; i++) {
    const date = month.set('date', i);
    const isHoliday = date.isoWeekday() === 6 || date.isoWeekday() === 7;
    titles.push({
      id: `${i}`,
      name: `${i}`,
      default: '',
      visible: true,
      align: 'center',
      width: '46px',
      class: isHoliday ? 'holiday' : '',
      cellClass: 'cp-padding__lr--1',
    });
  }

  const rows = [];
  const totalItems = data.length;
  for (let i = 0; i < totalItems; i++) {
    const item = data[i];
    const row: any = {
      fullName: getFullName(item),
      serviceType: item.serviceTypeName,
      serviceTypeId: item.serviceTypeId,
    };

    Object.keys(item.dates).forEach((dateString: string) => {
      const dayNumber = moment(dateString, 'YYYY_MM_DD').format('D');
      row[dayNumber] = item.dates[dateString];
    });

    rows.push(row);
  }

  const table = {
    titles,
    rows,
    sortable: ['sortable'],
    totalItems,
  };

  const uiTable = new UITable(table as any);

  return uiTable.setSortableValues(options && options.sort).getTable() as any;
}

export function prepareReportInfo(data: any) {
  const result: ReviseModelInterface = {
    shiftId: data.clientMarketShiftId,
    lkTime: data.lkTime,
    lkPrice: data.lkPrice,
    lkQuantity: data.lkQuantity,
    time: data.time,
    shiftLunch: data.shiftLunch,
    shiftLunchIsPaid: data.shiftLunchIsPaid,
    isTest: data.isTest,
    isTestMarket: data.isTestMarket,
    employee: getShortFullName(data),
    employeeId: data.employeeId,
    vacancy: data.serviceTypeName,
    date: moment(data.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    start: moment(data.shiftStart, 'HH:mm:ss').format('HH:mm'),
    end: moment(data.shiftEnd, 'HH:mm:ss').format('HH:mm'),
    unitType: data.unitType,
    quantity: data.quantity,
    price: data.price,
    comment: data.comment,
    lk: {
      status: '',
      substatus: '',
    },
    gigant: {
      status: '',
      substatus: '',
    },
  };

  let lkStart, lkEnd, lkLunch, start, end, lunch;
  if (data.lkStart && data.lkEnd) {
    lkStart = dateFormat(data.lkStart, { from: 'HH:mm:ss', to: 'HH:mm' });
    lkEnd = dateFormat(data.lkEnd, { from: 'HH:mm:ss', to: 'HH:mm' });
    lkLunch = minutesToTime(data.lkLunch || 0);
  }

  result.lk.start = lkStart;
  result.lk.end = lkEnd;
  result.lk.lunch = lkLunch;
  result.lk.status = data.lkStatus;
  result.lk.substatus = data.lkSubstatus;

  if (data.start && data.end) {
    start = dateFormat(data.start, { from: 'HH:mm:ss', to: 'HH:mm' });
    end = dateFormat(data.end, { from: 'HH:mm:ss', to: 'HH:mm' });
    lunch = minutesToTime(data.lunch || 0);
  }

  result.gigant.start = start;
  result.gigant.end = end;
  result.gigant.lunch = lunch;
  result.gigant.status = data.status;
  result.gigant.substatus = data.substatus;

  return result;
}
