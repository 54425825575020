
import { Component, Vue } from '@/lib/decorator';

import ReviseModule from '@/store/tasks/revise';
import AppTable from '@/components/ui/table/Table.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import AppTableColumnContext from '@/components/ui/table/TableColumnContext.vue';
import IconFont from '@/components/icons/IconFont.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TasksEntityModule from '@/store/tasks/entity';
import DatePickerFilter from '@/components/form/DatePickerFilter.vue';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterModel, FilterSettings } from '@/lib/layouts/page/filter.interface';
import FilterInstant from '@/components/FilterInstant.vue';
import moment from 'moment/moment.js';
import { ReviseModalDateType } from '@/store/tasks/revise/modalParams';
import TimeFormatter from '@/components/TimeFormatter.vue';
import { GuiFormButton, GuiLoader } from '@library/gigant_ui';
import { GuiFormCheckbox } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    GuiFormButton,
    GuiFormCheckbox,
    TimeFormatter,
    TitleReturn,
    TabsNav,
    IconFont,
    AppTableColumnContext,
    StatusActive,
    TextPhone,
    TextDatetime,
    AppTable,
    DatePickerFilter,
    FilterInstant,
  },
})
export default class RevisePage extends Vue {
  isDownloadInProgress = false;

  get tabsNav(): TabsNavInterface {
    return TasksEntityModule.tabsNav;
  }

  get currentTab(): string {
    return TasksEntityModule.tabsNav.revise?.id;
  }

  get settings(): PageInterface {
    return ReviseModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return ReviseModule.filter.filterSettings;
  }

  get model(): Record<string, ReviseModalDateType> {
    return ReviseModule.modalParams.dateRange.model as Record<string, ReviseModalDateType>;
  }

  get filterModel(): FilterModel {
    return ReviseModule.filter.filterSettings.filterModel;
  }

  get reportWithUnreconciled(): boolean {
    return ReviseModule.reportWithUnreconciled;
  }

  async resetFilters(): Promise<void> {
    await ReviseModule.setPageToBegin();
    await ReviseModule.clearShopsSort();
    await ReviseModule.filter.resetFilter();
    await ReviseModule.getList();
  }

  selectDateEnd(date: { day: string; month: string; year: string }): void {
    ReviseModule.updateDateEnd(`${date.day}/${date.month}/${date.year}`);
  }

  selectDateStart(date: { day: string; month: string; year: string }): void {
    ReviseModule.updateDateStart(`${date.day}/${date.month}/${date.year}`);
  }

  async updateList(): Promise<void> {
    await ReviseModule.setPageToBegin();
    await ReviseModule.filter.updateFilter();
    await ReviseModule.getList();
  }

  clickRow(item: { id: string; partnerUuid: string }): void {
    const monthPeriod = moment().format('YYYY-MM');

    this.$router.push({
      name: 'revise_shop',
      params: { shopId: item.id },
      query: { partnerUuid: item.partnerUuid, monthPeriod },
    });
  }

  selectAmount(value: string): void {
    ReviseModule.updatePageAmountItems(value);
  }

  sort(header: { id: string; sort: { value: string } }): void {
    ReviseModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  updateReportWithUnreconciled(value: number): void {
    ReviseModule.SET_REPORT_WITH_UNRECONCILED(value);
  }

  async downloadReport(): Promise<void> {
    this.isDownloadInProgress = true;

    try {
      await ReviseModule.downloadReviseReport();
    } finally {
      this.isDownloadInProgress = false;
    }
  }

  async mounted(): Promise<void> {
    await TasksEntityModule.initTabsItems();
    await ReviseModule.init();
  }
}
