import { watch } from 'vue';
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import UserModule from '@/store/user';
import PageEntity from '@/lib/layouts/page/pageEntity';
import filterModel from './filter';
import { downloadReviseReport, getReviseShopsList, getReviseShopsTotal } from '@/api/revise';
import { getClients } from '@/api/client';
import { getAllPartnersList } from '@/api/partners';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import ModalsModule from '@/store/modals';
import ReviseFilterModule from './filterEntity';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareShopList } from '@/lib/Revise';
import { convertObjectToQueryParams, saveBlob } from '@/lib/Utils';
import moment from 'moment/moment.js';
import { CustomerResponseModel } from '@/interfaces/models/customer.interface';
import { Checkbox } from '@/interfaces/filter.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import { CatchFormResponse } from '@/interfaces/shared';
import { getServiceTypeAll } from '@/api/templates';
import { ServiceTypeMin } from '@/interfaces/models/serviceType.interface';
import { modalParams, ReviseModalDateType, ReviseModalParamsType } from '@/store/tasks/revise/modalParams';

export const MODULE_NAME = 'revise';
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ReviseModule extends PageBaseModule {
  modalParams: ReviseModalParamsType = modalParams;
  filter: Filter;
  reportWithUnreconciled = false;

  allServiceTypes: ServiceTypeMin[] = [];

  constructor(module: ReviseModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Сверка');
    page.values.actionPagination = 'revise/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(ReviseFilterModule);
    this.filter.setFilterName('reviseFilter');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        if (!isSupervisor) {
          this.filter.resetSelect('partners');
        }
      },
      { immediate: true }
    );
  }

  @Mutation
  SET_SORT(sorts: PageSort): void {
    this.pageSettings.sort = sorts;
    window.localStorage.shopsSort = JSON.stringify(sorts);
  }

  @Mutation
  UPDATE_DATE_START(date: string) {
    (this.modalParams.dateRange.model as Record<string, ReviseModalDateType>).dateFrom.value = date;
    window.localStorage.reviseFrom = JSON.stringify(date);
  }

  @Mutation
  UPDATE_DATE_END(date: string) {
    (this.modalParams.dateRange.model as Record<string, ReviseModalDateType>).dateTo.value = date;
    window.localStorage.reviseTo = JSON.stringify(date);
  }

  @Mutation
  SET_REPORT_WITH_UNRECONCILED(value: boolean | number): void {
    this.reportWithUnreconciled = !!value;
  }

  @Mutation
  SET_ALL_SERVICE_TYPES(serviceTypes: ServiceTypeMin[]): void {
    this.allServiceTypes = serviceTypes;
  }

  @Action({ rawError: true })
  async init() {
    this.context.commit('SET_SORT', window.localStorage.reviseSort ? JSON.parse(window.localStorage.reviseSort) : {});
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.initRange();
    await this.filter.init();
    await this.getList();
  }

  @Action({ rawError: true })
  async initRange() {
    const start = window.localStorage.reviseFrom
      ? JSON.parse(window.localStorage.reviseFrom)
      : moment().startOf('month').format('DD/MM/YY');
    this.context.commit('UPDATE_DATE_START', start);
    const end = window.localStorage.reviseTo
      ? JSON.parse(window.localStorage.reviseTo)
      : moment().endOf('month').format('DD/MM/YY');
    this.context.commit('UPDATE_DATE_END', end);
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filterDates = await this.getFilterDates();
      const filter = `&${filterDates}${this.filter.filterSettings.filter}`;
      const table: TableApiInterface = await getReviseShopsList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);
      const total: TableApiInterface = await getReviseShopsTotal(this.pageSettings.pageCurrent, itemsQuery, sort, filter);
      table.totalData = total.rows[0];
      await this.context.dispatch('setList', table);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response?.data?.message ?? error,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  getStartMoment() {
    return moment((this.modalParams.dateRange.model as Record<string, ReviseModalDateType>).dateFrom.value, 'DD/MM/YY');
  }

  @Action({ rawError: true })
  getEndMoment() {
    return moment((this.modalParams.dateRange.model as Record<string, ReviseModalDateType>).dateTo.value, 'DD/MM/YY');
  }

  @Action({ rawError: true })
  async getFilterDates() {
    const dateStartFormatted = (await this.getStartMoment()).format('YYYY-MM-DD');
    const dateEndFormatted = (await this.getEndMoment()).format('YYYY-MM-DD');

    let datesFilter = `&filters[0][id]=dateFrom&filters[0][value]=${dateStartFormatted}`;
    datesFilter += `&filters[1][id]=dateTo&filters[1][value]=${dateEndFormatted}&`;

    return datesFilter;
  }

  @Action({ rawError: true })
  async updateDateStart(date: string) {
    await this.context.commit('UPDATE_DATE_START', date);

    if ((await this.getStartMoment()) > (await this.getEndMoment())) {
      await this.context.commit('UPDATE_DATE_END', date);
    }

    await this.getList();
  }

  @Action({ rawError: true })
  async updateDateEnd(date: string) {
    await this.context.commit('UPDATE_DATE_END', date);

    if ((await this.getStartMoment()) >= (await this.getEndMoment())) {
      await this.context.commit('UPDATE_DATE_START', date);
    }

    await this.getList();
  }

  @Action({ rawError: true })
  async setList(table: TableApiInterface) {
    const sort = Object.values(this.pageSettings.sort);
    this.context.commit('SET_TABLE', prepareShopList(table, { sort }));
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    await this.context.commit('SET_PAGE', parseInt(number));
    await this.getList();
  }

  @Action({ rawError: true })
  clearShopsSort() {
    this.context.commit('SET_SORT', {});
  }

  @Action({ rawError: true })
  deleteShopModal(shopId: string) {
    this.context.commit('SET_DELETE_ID', shopId);
    ModalsModule.updateParams(this.modalParams.delete);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  async initClientsSelect(): Promise<{ id: string; value: string; name: string }[]> {
    try {
      const result = await getClients();

      return Object.values(result.clients as CustomerResponseModel[]).map((client: CustomerResponseModel) => {
        return {
          id: client.id,
          value: client.name,
          name: client.name,
        };
      });
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async initMonthsSelect() {
    const items = [];
    const currentMonth = moment().startOf('month');

    for (let i = 0; i < 12; i++) {
      const itemMonth = currentMonth.clone().subtract(i, 'month');

      const id = itemMonth.format('YYYY-MM');
      const value = itemMonth.format('MMMM YYYY').replace(/^[а-я]/, (firstLetter) => firstLetter.toUpperCase());

      items.push({ id, value });
    }

    return items;
  }

  @Action({ rawError: true })
  async getAllServiceTypes() {
    if (!this.allServiceTypes.length) {
      this.SET_ALL_SERVICE_TYPES(await getServiceTypeAll());
    }

    return this.allServiceTypes;
  }

  @Action({ rawError: true })
  prepareServiceTypeItems(serviceTypes: ServiceTypeMin[]) {
    const filterModelList = (this.filter.filterSettings.filterModel.serviceTypes?.list ?? []) as Checkbox[];

    return serviceTypes.map((serviceType) => {
      const filterModelItem = filterModelList.find((item) => item.id.toString() === serviceType.id.toString());
      const checked = filterModelItem?.checked ?? false;

      return {
        id: serviceType.id,
        value: serviceType.id,
        name: serviceType.name,
        checked: checked,
      };
    });
  }

  @Action({ rawError: true })
  async initServiceTypeSelect() {
    try {
      const serviceTypes = await this.getAllServiceTypes();

      return await this.prepareServiceTypeItems(serviceTypes);
    } catch (e) {
      return [];
    }
  }

  @Action({ rawError: true })
  async initPartnersSelect() {
    try {
      if (!UserModule.isSupervisor) {
        return [];
      }

      const partnersAll: PartnerSelectListItem[] = await getAllPartnersList();

      return Object.values(partnersAll)
        .filter((partner: PartnerSelectListItem) => partner.isSupplier)
        .map((partner: PartnerSelectListItem) => {
          return {
            id: partner.uuid,
            value: partner.legalName,
            name: partner.legalName,
          };
        });
    } catch (e) {
      return [];
    }
  }

  @Action({ rawError: true })
  async downloadReviseReport() {
    try {
      const filterModel = this.filter.filterSettings.filterModel;
      const getCheckedItems = (key: string) => filterModel[key]?.checkedItems?.map(({ id }) => id) ?? [];

      const filters: Record<string, unknown> = {
        dateFrom: moment(this.modalParams.dateRange.model?.dateFrom?.value, 'DD/MM/YY').format('YYYY-MM-DD') ?? '',
        dateTo: moment(this.modalParams.dateRange.model?.dateTo?.value, 'DD/MM/YY').format('YYYY-MM-DD') ?? '',
        serviceTypes: getCheckedItems('serviceTypes'),
        clients: getCheckedItems('clients'),
        fullMarketName: filterModel.fullMarketName.value,
        partners: getCheckedItems('partners'),
        showDone: '1',
      };

      if (!this.reportWithUnreconciled) {
        filters.onlyDone = '1';
      }

      const queryString = convertObjectToQueryParams(filters);
      const result = await downloadReviseReport(queryString);
      const filename = `report_${this.modalParams.dateRange.model.dateFrom.value}-${this.modalParams.dateRange.model.dateTo.value}.xlsx`;

      saveBlob(result, filename);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse).response?.data?.message ?? error,
        type: 'fail',
      });
    }
  }
}

export default getModule(ReviseModule);
