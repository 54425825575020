import { watch } from 'vue';
import moment from 'moment';
import UserModule from '@/store/user';
import FilterFactory from '@/lib/layouts/page/filterFactory';
import { SelectItem } from '@/lib/formFactory/select.interface';

export default class ShopsFilter {
  filterHandlers = {
    update: 'reviseFilter/updateFilter',
    reset: 'reviseFilter/resetFilter',
    resetState: 'reviseFilter/resetFilterState',
    removeSelected: 'reviseFilter/removeSelectedFilter',
  };
  filterModel: {};

  get defaultMonthPeriod(): SelectItem {
    const currentMonth = moment();
    const id = currentMonth.format('YYYY-MM');
    const value = currentMonth.format('MMMM YYYY').replace(/^[а-я]/, (firstLetter) => firstLetter.toUpperCase());

    return { id, value };
  }

  constructor() {
    const filter = new FilterFactory();

    filter.createMultiSelectEntity('clients');
    filter.setTitle('clients', 'Клиент');
    filter.setName('clients', 'multi_select_client');
    filter.setClassName('clients', 'cp-width--200');
    filter.setAction('clients', 'reviseFilter/updateMultiSelect');
    filter.setInitAction('clients', 'revise/initClientsSelect');
    filter.setSearchEnabled('clients', true);

    filter.createMultiSelectEntity('serviceTypes');
    filter.setTitle('serviceTypes', 'Тип услуги');
    filter.setName('serviceTypes', 'multi_select_service_type');
    filter.setClassName('serviceTypes', 'cp-width--200');
    filter.setAction('serviceTypes', 'reviseFilter/updateMultiSelect');
    filter.setInitAction('serviceTypes', 'revise/initServiceTypeSelect');
    filter.setSearchEnabled('serviceTypes', true);

    filter.createSearchEntity('fullMarketName');
    filter.setPlaceholder('fullMarketName', 'Объект');
    filter.setName('fullMarketName', 'search_string_fullMarketName');
    filter.setClassName('fullMarketName', 'cp-width--200');
    filter.setAction('fullMarketName', 'reviseFilter/updateSearch');

    filter.createMultiSelectEntity('partners');
    filter.setTitle('partners', 'Партнёр');
    filter.setName('partners', 'multi_select_partner');
    filter.setClassName('partners', 'cp-width--200');
    filter.setAction('partners', 'reviseFilter/updateMultiSelect');
    filter.setInitAction('partners', 'revise/initPartnersSelect');
    filter.setSearchEnabled('partners', true);

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        filter.setIsHidden('partners', !isSupervisor);
      },
      { immediate: true }
    );

    this.filterModel = filter.filterModel;
  }
}
